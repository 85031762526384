import dynamic from 'next/dynamic'
import { useMemo } from 'react'

const Icons = {
  'arrow-right': dynamic(() => import('./icons/ArrowRight')),
  'arrow-up': dynamic(() => import('./icons/ArrowUp')),
  'arrow-down': dynamic(() => import('./icons/ArrowDown')),
  'battery-charging': dynamic(() => import('./icons/BatteryCharging')),
  check: dynamic(() => import('./icons/Check')),
  'chevron-down': dynamic(() => import('./icons/ChevronDown')),
  'chevron-up': dynamic(() => import('./icons/ChevronUp')),
  close: dynamic(() => import('./icons/Close')),
  'e-tag-coupon': dynamic(() => import('./icons/ETagCoupon')),
  'customer-support': dynamic(() => import('./icons/CustomerSupport')),
  'dollar-shield': dynamic(() => import('./icons/DollarShield')),
  'date-calendar': dynamic(() => import('./icons/DateCalendar')),
  'date-clock': dynamic(() => import('./icons/DateClock')),
  'form-denied': dynamic(() => import('./icons/FormDenied')),
  loan: dynamic(() => import('./icons/Loan')),
  'life-buoy': dynamic(() => import('./icons/LifeBuoy')),
  'logo-on': dynamic(() => import('./icons/LogoOn')),
  'magnifying-search': dynamic(() => import('./icons/MagnifyingSearch')),
  image: dynamic(() => import('./icons/Image')),
  money: dynamic(() => import('./icons/Money')),
  'mobile-card': dynamic(() => import('./icons/MobileCard')),
  'message-r-chat': dynamic(() => import('./icons/MessageRChat')),
  order: dynamic(() => import('./icons/Order')),
  'arrow-swap-vertical': dynamic(() => import('./icons/ArrowSwapVertical')),
  phone: dynamic(() => import('./icons/Phone')),
  'phone-card': dynamic(() => import('./icons/PhoneCard')),
  'phone-heart': dynamic(() => import('./icons/PhoneHeart')),
  plus: dynamic(() => import('./icons/Plus')),
  'pos-small': dynamic(() => import('./icons/PosSmall')),
  'phone-x': dynamic(() => import('./icons/PhoneX')),
  'qr-code-pix': dynamic(() => import('./icons/QrCodePix')),
  question: dynamic(() => import('./icons/Question')),
  'question-circle': dynamic(() => import('./icons/QuestionCircle')),
  'dollar-circle': dynamic(() => import('./icons/DollarCircle')),
  certificate: dynamic(() => import('./icons/Certificate')),
  'x-tag-delete': dynamic(() => import('./icons/XTagDelete')),
  'thumbs-up-empty': dynamic(() => import('./icons/ThumbsUpEmpty')),
  'trend-up': dynamic(() => import('./icons/TrendUp')),
  'stone-agent': dynamic(() => import('./icons/StoneAgent')),
  download: dynamic(() => import('./icons/Download')),
  'security-lock': dynamic(() => import('./icons/SecurityLock')),
  'smart-phone': dynamic(() => import('./icons/SmartPhone')),
  'shopping-cart': dynamic(() => import('./icons/ShoppingCart')),
  truck: dynamic(() => import('./icons/AutoTruck')),
  whatsapp: dynamic(() => import('./icons/Whatsapp')),
  'cent-piggy-bank': dynamic(() => import('./icons/CentPiggyBank')),
  'replace-pos': dynamic(() => import('./icons/ReplacePos')),
  pig: dynamic(() => import('./icons/Pig')),
  'cent-hand': dynamic(() => import('./icons/CentHand')),
  'cent-hand-2x': dynamic(() => import('./icons/CentHand2x')),
  chat: dynamic(() => import('./icons/Chat')),
  invoice: dynamic(() => import('./icons/Invoice')),
  'mobile-banking': dynamic(() => import('./icons/MobileBanking')),
  videos: dynamic(() => import('./icons/Videos')),
  'cent-clock': dynamic(() => import('./icons/CentClock')),
  'dollar-phone': dynamic(() => import('./icons/DollarPhone')),
  'dollar-phone-40px': dynamic(() => import('./icons/DollarPhone40px')),
  'signal-contactless': dynamic(() => import('./icons/SignalContactless')),
  'phone-check': dynamic(() => import('./icons/PhoneCheck')),
  'pos-receipt-list': dynamic(() => import('./icons/PosReceiptList')),
  'pos-qr': dynamic(() => import('./icons/PosQr')),
  pos: dynamic(() => import('./icons/Pos')),
  calculator: dynamic(() => import('./icons/Calculator')),
  'signal-loader': dynamic(() => import('./icons/SignalLoader')),
  'stone-solution': dynamic(() => import('./icons/StoneSolution')),
  'mouse-touch-point': dynamic(() => import('./icons/MouseTouchPoint')),
  'testimonials-machine': dynamic(() => import('./icons/TestimonialsMachine')),
  'thumbs-up': dynamic(() => import('./icons/ThumbsUp')),
  'auto-truck': dynamic(() => import('./icons/AutoTruck')),
  coupon: dynamic(() => import('./icons/Coupon')),
  percent: dynamic(() => import('./icons/Percent')),
  'phone-mobile': dynamic(() => import('./icons/PhoneMobile')),
  'maquininha-carrossel': dynamic(() => import('./icons/MaquininhaCarrossel')),
  'transferencia-carrossel': dynamic(
    () => import('./icons/TransferenciaCarrossel')
  ),
  'chart-small': dynamic(() => import('./icons/ChartSmall')),
  'face-happy': dynamic(() => import('./icons/FaceHappy')),
  'file-text': dynamic(() => import('./icons/FileText')),
  'user-users': dynamic(() => import('./icons/UserUsers')),
  'dollar-off': dynamic(() => import('./icons/DollarOff')),
  'check-circle': dynamic(() => import('./icons/CheckCircle')),
  'card-credit': dynamic(() => import('./icons/CardCredit')),
  zap: dynamic(() => import('./icons/Zap')),
  'dollar-sign': dynamic(() => import('./icons/DollarSign')),
  'arrow-swap-horizontal': dynamic(() => import('./icons/ArrowSwapHorizontal')),
  'logo-pix': dynamic(() => import('./icons/LogoPix')),
  'map-pins': dynamic(() => import('./icons/MapPins')),
  apps: dynamic(() => import('./icons/Apps')),
  'no-bank': dynamic(() => import('./icons/NoBank')),
  'money-icon': dynamic(() => import('./icons/MoneyIcon')),
  'app-ton': dynamic(() => import('./icons/AppTon')),
  'security-dollar': dynamic(() => import('./icons/SecurityDollar')),
  'hand-heart': dynamic(() => import('./icons/HandHeart')),
  'arrow-fill-left': dynamic(() => import('./icons/ArrowFillLeft')),
  'emotiton-loving': dynamic(() => import('./icons/EmotiTonLoving')),
  emotiton: dynamic(() => import('./icons/EmotiTon')),
  'emotiton-money': dynamic(() => import('./icons/EmojiTonMoney')),
  link: dynamic(() => import('./icons/Link')),
  'card-hand': dynamic(() => import('./icons/CardHand')),
  'cash-register': dynamic(() => import('./icons/CashRegister')),
  'stone-shop': dynamic(() => import('./icons/StoneShop')),
  'calendar-check/green': dynamic(() => import('./icons/CalendarCheckGreen')),
  'tapton/green': dynamic(() => import('./icons/TaptonGreen')),
  'account/green': dynamic(() => import('./icons/AccountGreen')),
  'rex/green': dynamic(() => import('./icons/RexGreen')),
  'id-document': dynamic(() => import('./icons/IdDocument')),
  'security-shield': dynamic(() => import('./icons/SecurityShield')),
  'plus-circle': dynamic(() => import('./icons/PlusCircle')),
  'familhao-ton': dynamic(() => import('./icons/FamilhaoTon')),
  // icons 2x
  'signal-wifi-2': dynamic(() => import('./icons/SignalWifi2')),
  'arrow-left-2x': dynamic(() => import('./icons/ArrowLeft2x')),
  'arrow-right-2x': dynamic(() => import('./icons/ArrowRight2x')),
  'logos-new': dynamic(() => import('./icons/LogosNew')),
  'pos-qr-2x': dynamic(() => import('./icons/PosQr2x')),
  'dollar-phone-2x': dynamic(() => import('./icons/DollarPhone2x')),
  'logo-ton-stone': dynamic(() => import('./icons/LogoTonStone')),
  'check-2x': dynamic(() => import('./icons/Check2x')),
  'transport-car': dynamic(() => import('./icons/TransportCar')),
  compass: dynamic(() => import('./icons/Compass')),
  shop: dynamic(() => import('./icons/Shop')),

  'animation/phone': dynamic(() => import('./icons/animations/Phone')),
  'animation/whatsapp': dynamic(() => import('./icons/animations/Whatsapp')),
  'animation/date-clock': dynamic(() => import('./icons/animations/DateClock')),

  'apps-lib-sm': dynamic(() => import('./icons/lib/Apps')),
  'bilheton-lib-sm': dynamic(() => import('./icons/lib/Bilheton')),
  'emotiton-lib-sm': dynamic(() => import('./icons/lib/EmotiTon')),
  edit: dynamic(() => import('./icons/lib/Edit')),
  heart: dynamic(() => import('./icons/Heart')),
  'map-pin': dynamic(() => import('./icons/MapPin')),
  handshake: dynamic(() => import('./icons/HandShake')),
  'tag-loyalty': dynamic(() => import('./icons/TagLoyalty')),
  'truck-green': dynamic(() => import('./icons/TruckGreen')),
  'tapton-big': dynamic(() => import('./icons/TaptonBig')),
  'user-green': dynamic(() => import('./icons/UserGreen'))
} as const

export type IconsNames = keyof typeof Icons

type IconProps = {
  name: IconsNames
  className?: string
}

const Icon: React.FC<IconProps> = ({ name, className }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const NewIcon = useMemo(() => Icons[name], [name]) as any

  return <NewIcon className={className} />
}

export default Icon
